import { getBoardIntegrations } from "frontend/api";

export const getMondayBoardItemMapping = async (
  documentId: string,
  integrationId: string
): Promise<{
  mapping: Record<string, string>;
  shouldApplyForAll: boolean;
} | null> => {
  const allConfigurations = await getBoardIntegrations(documentId);
  const configuration = allConfigurations.find((c: any) => c.id === integrationId);
  return configuration.configuration?.columnMappings;
};

export const combineMappingWithData = (
  columnMappings: {
    mapping: Record<string, string>;
    shouldApplyForAll: boolean;
  } | null,
  item: {
    name: string;
    columnValues: {
      id: string;
      type: string;
      value: string;
    }[];
  }
) => {
  const startId = columnMappings?.mapping?.start_date;
  const endId = columnMappings?.mapping?.end_date;
  const dependencyId = columnMappings?.mapping?.dependency;

  const dateColumns = item?.columnValues?.filter((column: any) => column.type === "date");
  const startDate = dateColumns?.find((column: any) => column.id === startId)?.value || "";
  const endDate = dateColumns?.find((column: any) => column.id === endId)?.value || "";
  const title = item?.name ?? "";

  const dependentTaskIds = item?.columnValues?.find((column: any) => column.id === dependencyId)?.value || "";
  let dependencies: string[] = [];
  if (dependentTaskIds) {
    dependencies =
      (JSON.parse(dependentTaskIds)?.linkedPulseIds ?? []).map((d: any) => d.linkedPulseId.toString()) ?? [];
  }

  return {
    startDate: new Date(new Date(startDate).toDateString()).getTime(),
    endDate: new Date(new Date(endDate).toDateString()).getTime(),
    dependencies,
    title,
    startId,
    endId,
    dependencyId,
  };
};
