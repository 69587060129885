import { ReadTransaction } from "@workcanvas/reflect";
import { SupportedGanttElements } from "elements/gantt/types";
import { getFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GanttElement, IntegrationItem, integrationItemPrefix } from "shared/datamodel/schemas";
import { taskCardPrefix } from "shared/datamodel/task-card";
import UndoRedoStack from "utils/undo-redo/undo-redo-stack";
import { Reflect } from "@workcanvas/reflect/client";
import { M } from "shared/datamodel/mutators";
import { trackGanttEvent } from "elements/gantt/utils";

const CUSTOM_OPTION = {
  id: "Custom",
  title: "Custom row",
  type: "custom",
};

export const useGanttToolbarCustomRows = ({
  id,
  gantt,
  undoRedoStack,
  reflect,
  getOptions,
}: {
  id: string;
  gantt: GanttElement;
  undoRedoStack: UndoRedoStack;
  reflect: Reflect<M>;
  getOptions: (data: IntegrationItem[]) => Promise<
    Map<
      string,
      {
        id: string;
        title: string;
        type: string;
      }
    >
  >;
}) => {
  const [options, setOptions] = useState<
    Map<
      string,
      {
        id: string;
        title: string;
        type: string;
      }
    >
  >(new Map());
  const [currentRowIdentifier, setCurrentRowIdentifier] = useState<string>(gantt.customRows?.id ?? "Custom");

  const query = useCallback(
    async (tx: ReadTransaction) => {
      const allItems = await tx.scan().entries().toArray();
      const tasks = allItems
        .filter(
          ([id]) =>
            id.startsWith(taskCardPrefix) ||
            (id.startsWith(integrationItemPrefix) && getFeatureFlag("gantt-monday-integration-items"))
        )
        .map(([_, value]) => value as SupportedGanttElements)
        .filter(
          (task) => !task.hidden && task.containerId === id && task.type === "integrationItem"
        ) as IntegrationItem[];
      return tasks;
    },
    [id]
  );

  const changeCustomRow = useCallback(
    (customRow: { id: string; type: string; title: string }) => {
      if (gantt.customRows?.id !== customRow.id) {
        undoRedoStack.startSequence("gantt-splits");
        undoRedoStack.patchElement(
          id,
          (draft: GanttElement) => {
            if (customRow.id === CUSTOM_OPTION.id) {
              delete draft.customRows;
            } else {
              draft.customRows = customRow;
            }
          },
          "gantt-splits"
        );
        trackGanttEvent("gantt_custom_rows_changed");
      }
      setCurrentRowIdentifier(customRow.id);
    },
    [gantt, id, undoRedoStack]
  );

  useEffect(() => {
    return reflect.subscribe(query, async (data: IntegrationItem[]) => {
      const supportedColumns = await getOptions(data);
      supportedColumns.set("Custom", CUSTOM_OPTION);
      setOptions(supportedColumns);
    });
  }, []);

  useEffect(() => {
    if (options.size > 0) {
      const newCurrentRowIdentifier = options.has(currentRowIdentifier)
        ? options.get(currentRowIdentifier)?.id
        : "Custom";
      if (newCurrentRowIdentifier !== currentRowIdentifier) {
        setCurrentRowIdentifier(newCurrentRowIdentifier ?? "");
      }
    }
  }, [gantt.customRows?.id, options]);

  const title = useMemo(() => {
    return options.get(currentRowIdentifier)?.title ?? CUSTOM_OPTION.title;
  }, [currentRowIdentifier, options]);

  const optionsArray = useMemo(() => {
    const array = [...options.values()];
    array.sort((a) => (a.title === CUSTOM_OPTION.title ? -1 : 1));
    return array;
  }, [options]);

  const result = useMemo(() => {
    return {
      title,
      options: optionsArray,
      currentRowIdentifier,
      changeCustomRow,
    };
  }, [changeCustomRow, currentRowIdentifier, optionsArray, title]);

  return result;
};
