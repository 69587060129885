import { BoardContext } from "elements/index";
import GanttController from "../controller";
import { createEmptyContext, createMockReflect } from "elements/utils";
import { placeGanttElement } from "shared/datamodel/gantt";

export default class GanttGhostController extends GanttController {
  constructor(context?: BoardContext) {
    const startDate = new Date().getTime();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 14);
    const element = placeGanttElement({ x: 0, y: 0 }, startDate, endDate.getTime()).item;

    context = createEmptyContext({
      reflectOverrides: createMockReflect(),
    });

    super("dummy", element, context);
  }

  canAddSplitColumnRight(): boolean {
    return false;
  }

  canAddSplitRowBelow(): boolean {
    return false;
  }

  async canAddNewTask(): Promise<boolean> {
    return false;
  }
}
