import axios from "axios";
import Pusher, { Channel, ChannelAuthorizationCallback } from "pusher-js";

const key = process.env.PUSHER_APP_KEY;
export function createPusherInstance() {
  if (!key) {
    throw new Error("PUSHER_APP_KEY not set");
  }
  const authToken = sessionStorage.getItem("x-canvas-auth-token");
  return new Pusher(key, {
    cluster: process.env.PUSHER_CLUSTER!,
    authorizer: (channel: Channel, _options: any) => {
      return {
        authorize: (socketId: string, callback: ChannelAuthorizationCallback) => {
          axios({
            method: "POST",
            url: `${process.env.HOST}${process.env.NEXT_BRANCH_PATH}/pusher/auth`,
            headers: {
              "x-canvas-auth-token": authToken || "",
            },
            data: {
              channel_name: channel.name,
              socket_id: socketId,
            },
            withCredentials: true,
          })
            .then((response) => {
              callback(null, response.data);
            })
            .catch((error) => {
              callback(new Error(`Error authenticating with server: ${error}`), {
                auth: "",
              });
            });
        },
      };
    },
  });
}
