import GanttSplitCell from "elements/gantt/components/split-cell/split-cell";
import { Group, Rect } from "react-konva";
import { useRef, useState } from "react";
import { getCornerRadius } from "elements/gantt/utils";
import Constants from "elements/gantt/constants";
import { IGanttSplitColumnController } from "../controllers/split-column-controller";
import useObservableController from "elements/hooks/use-observable-controller";
import BoundingBox from "frontend/geometry/bounding-box";
import { measureNodesScreenSpace } from "utils/node-utils";
import SplitColumnToolbar from "./split-column-toolbar/split-column-toolbar";
import { useIsPresenting } from "frontend/hooks/use-is-presenting";

export default function GanttSplitColumn({ controller }: { controller: IGanttSplitColumnController }) {
  useObservableController(controller);
  const isPresenting = useIsPresenting();

  const ref = useRef<any>();
  const cellBbox: BoundingBox | null =
    ref.current && BoundingBox.expandOnAll(measureNodesScreenSpace([ref.current], { skipStroke: true }));

  const [hovered, setHovered] = useState(false);

  function renderCells() {
    return controller
      .getColumnCells()
      .sort((a, b) => (a.isSelected() ? 1 : b.isSelected() ? -1 : 0))
      .map((cell) => <GanttSplitCell key={cell.getRowId()} controller={cell} />);
  }

  function getBoundingRect() {
    const rects = controller.getColumnCells().map((cell) => cell.getRect());
    const width = rects[0].width;
    const x = rects[0].x;
    const y = Math.min(...rects.map((rect) => rect.y));
    const height = Math.max(...rects.map((rect) => rect.y + rect.height)) - y;
    return { x, y, width, height };
  }

  function renderSelectionRect() {
    const { x, y, width, height } = getBoundingRect();
    return (
      <Rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill="transparent"
        stroke={Constants.SelectedCellBorderColor}
        strokeWidth={Constants.SelectedLayoutBorderWidth}
        cornerRadius={getBorderRadius()}
        listening={false}
      />
    );
  }

  function renderSelectAnchor() {
    const { x, y, width } = getBoundingRect();
    const anchorWidth = 30;
    return (
      <Group x={x} y={y - 40} id={`anchor-${controller.getSplitId()}`}>
        <Rect width={width} height={30} fill="transparent" onClick={() => controller.selectColumn()} />
        {hovered && !isPresenting && (
          <Rect
            x={(width - anchorWidth) / 2}
            y={15}
            width={anchorWidth}
            height={10}
            fill={Constants.SelectedCellBorderColor}
            stroke={Constants.SelectedCellBorderColor}
            strokeWidth={Constants.SelectedLayoutBorderWidth}
            cornerRadius={5}
            listening={false}
          />
        )}
      </Group>
    );
  }

  function getBorderRadius() {
    const cornerRadius = controller
      .getColumnCells()
      .map((cell) => cell.getCornerRadius())
      .reduce((acc, cornerRadius) => acc | cornerRadius, 0);
    return getCornerRadius(cornerRadius);
  }

  return (
    <Group
      id={`split-${controller.getSplitId()}`}
      ref={ref}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {renderCells()}
      {controller.showSelectionBorder() && renderSelectionRect()}
      {cellBbox && controller.showSelectionBorder() && (
        <SplitColumnToolbar cellBbox={cellBbox} controller={controller} />
      )}
      {renderSelectAnchor()}
    </Group>
  );
}
