import Konva from "konva";
import { Group, Rect, Text } from "react-konva";
import BaseElementComponent from "elements/base/base-element-component";
import consts from "shared/consts";
import { getTextColorForBackgroundColor } from "utils/color-utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { EditableText } from "frontend/editableText";
import { useEvent } from "react-use";
import { useDebounce } from "frontend/hooks/debounce";
import { unsetMouse } from "utils/mouse-cursor-utils";
import { differenceInDays, startOfDay } from "date-fns";
import { GanttOpenTaskCard } from "elements/gantt/icons/gantt-open-task-card";
import ganttConstant from "elements/gantt/constants";
import { GanttTaskCell } from "elements/gantt/layout-builder";
import useObservableController from "elements/hooks/use-observable-controller";
import { useAtomValue } from "jotai";
import { transformerRefAtom } from "state-atoms";
import { IGanttBaseCellController } from "elements/gantt/controllers/base-cell-controller";
import { TaskCard, IntegrationItem } from "shared/datamodel/schemas";
import { cleanDate } from "shared/util/date-utils";
import { GanttMondayIcon } from "elements/gantt/icons/gantt-monday-icon";
import { useBoardTasks } from "frontend/hooks/use-board-integrations";

const START_CORNER_RADIUS = [0, 5, 5, 0];
const END_CORNER_RADIUS = [5, 0, 0, 5];
const PADDING = ganttConstant.taskCellPadding;

export default function GanttTaskComponent({
  controller,
  isEditing,
}: {
  controller: IGanttBaseCellController<TaskCard> | IGanttBaseCellController<IntegrationItem>;
  isEditing: boolean;
}) {
  const transformerRef = useAtomValue(transformerRefAtom);
  const { updateItem } = useBoardTasks(controller.context.documentId, controller.context.pusherChannel);

  const title = controller.getTitle();
  const [currentTitle, setCurrentTitle] = useState(title ?? "");
  const debounceCurrentTitle = useDebounce(currentTitle, 500);

  const layout = controller.getLayout();
  const textRef = useRef<Konva.Text>(null);
  const isMondayItem = controller.id.includes(consts.CANVAS_ELEMENTS.INTEGRATION);

  useEffect(() => {
    if (title !== currentTitle) {
      if (controller.element.type === consts.CANVAS_ELEMENTS.INTEGRATION) {
        updateItem(controller.element.configuration.itemId, controller.element.integrationId, {
          name: debounceCurrentTitle,
        });
      }
      controller.changeTitle(debounceCurrentTitle);
    }
  }, [debounceCurrentTitle]);

  useObservableController(controller, () => {
    if (controller.isSelected()) {
      transformerRef?.current?.forceUpdate();
    }
  });

  const unsetMouseAction = useCallback((e?: any) => {
    const stage = Konva.stages[0];
    if (stage) {
      unsetMouse(e, stage);
    }
  }, []);

  useEvent("keydown", (e) => {
    if (e.key === "Escape") {
      unsetMouseAction(e);
    }
  });

  useEffect(() => {
    setCurrentTitle(title);
  }, [title]);

  if (!layout) {
    return null;
  }

  const {
    height: textHeight,
    width: textWidth,
    newTitle,
  } = measureTextTitle({
    isEditing: isEditing,
    layout,
    title: currentTitle,
  });
  const background = controller.getBackgroundColor();
  const textColor = getTextColorForBackgroundColor(background);

  const startShift = 0;
  const endShift = 0;

  const cornerRadius = calculateCornerRadius(endShift, startShift);

  if (layout.width - PADDING * 2 < 0) {
    return null;
  }
  let width = Math.max(0, layout.width - PADDING * 2);

  const height = layout.height - PADDING * 2;

  const endAmountDiff = getDaysDiff(controller.getEndDate() || cleanDate(new Date()), controller.getGanttEndDate());
  const startAmountDiff = getDaysDiff(
    controller.getGanttStartDate(),
    controller.getStartDate() || cleanDate(new Date())
  );

  width += Math.abs(startShift);
  width += Math.abs(endShift);

  return (
    <Group
      onMouseLeave={() => {
        unsetMouseAction();
      }}
    >
      <BaseElementComponent
        x={layout.x + PADDING + startShift}
        y={layout.y + PADDING}
        id={controller.id}
        element={controller.element}
        controller={controller}
        isConnectable={false}
        isSelectable={true}
        isDraggable={false}
        type={consts.CANVAS_ELEMENTS.TASK_CARD}
      >
        <Rect
          id={`task-${controller.id}`}
          width={width}
          height={height}
          fill={ganttConstant.TasksColors[background] ? ganttConstant.TasksColors[background][0] : background}
          cornerRadius={cornerRadius}
        />
        <Rect width={width} height={height} fill={"#FFFFFF80"} cornerRadius={cornerRadius} listening={false} />

        <Rect id={`task-${controller.id}`} width={8} height={height} fill={background} cornerRadius={[5, 0, 0, 5]} />
        {controller.isSelected() && (
          <Rect
            listening={false}
            width={width}
            height={height}
            fill={"transparent"}
            stroke="#00A1FF"
            cornerRadius={[5, 5, 5, 5]}
          />
        )}

        <EditableText
          x={PADDING + 8}
          y={PADDING - 5}
          width={textWidth}
          text={newTitle}
          fill={textColor}
          height={textHeight}
          fontSize={15}
          isEditing={isEditing}
          isFixedHeight={true}
          onChange={(newTextValue) => {
            setCurrentTitle(newTextValue);
          }}
          placeholder=""
          font={consts.DEFAULTS.FONT}
          onStopEditing={() => {
            unsetMouseAction();
          }}
        />

        {isMondayItem && <GanttMondayIcon x={width - PADDING * 3 - 2} y={height - PADDING * 2 - 2} />}

        {layout.isCutStart && startAmountDiff > 0 && (
          <Text
            id={`task-${controller.id}-startText`}
            x={PADDING}
            y={height - 15}
            width={width - PADDING * 2}
            text={`Started ${startAmountDiff} day${startAmountDiff > 1 ? "s" : ""} ago...`}
            fontFamily={"Poppins"}
            fill={textColor}
            height={20}
            ellipsis={true}
            ref={textRef}
            align="left"
            opacity={0.5}
          />
        )}
        {layout.isCutEnd && endAmountDiff > 0 && (
          <Text
            id={`task-${controller.id}-endText`}
            x={isMondayItem ? -PADDING * 2 : PADDING}
            y={height - 15}
            width={width - PADDING * 2}
            text={`${endAmountDiff} day${endAmountDiff > 1 ? "s" : ""} more...`}
            fontFamily={"Poppins"}
            fill={textColor}
            height={20}
            ellipsis={true}
            ref={textRef}
            align="right"
            opacity={0.5}
          />
        )}

        <Group x={width - 15} y={5} id={`task-${controller.id}-openTask`}>
          <GanttOpenTaskCard id={`task-${controller.id}-openTask`} />
        </Group>
      </BaseElementComponent>
    </Group>
  );
}

const getDaysDiff = (date1?: number | Date, date2?: number | Date) => {
  return Math.abs(differenceInDays(startOfDay(date1 ?? 0), startOfDay(date2 ?? 0)));
};

const calculateCornerRadius = (endShift: number, startShift: number) => {
  let cornerRadius: number | number[] = 5;
  if (endShift !== 0) {
    cornerRadius = END_CORNER_RADIUS;
  }
  if (startShift !== 0) {
    cornerRadius = START_CORNER_RADIUS;
  }

  if (!Array.isArray(cornerRadius) && cornerRadius < 0) {
    return 0;
  }

  return cornerRadius;
};

export const measureTextTitle = ({
  title = "",
  layout,
  isEditing,
}: {
  title: string;
  layout: GanttTaskCell;
  isEditing: boolean;
}) => {
  const width = layout.width - PADDING * 4 - 28;

  let height = layout.height - PADDING * 2;

  const amountOfRowsThatFit = Math.floor(height / ganttConstant.TaskTitleRowHeight);

  const ellipsedText = title
    .split("\n")
    .slice(0, amountOfRowsThatFit - 1)
    .concat("...")
    .map((line) => line.trim())
    .join("\n");

  const linesCount = title.split("\n").length;
  const newText = amountOfRowsThatFit >= linesCount ? title : ellipsedText;
  const textBlockHeight = Math.min(linesCount, amountOfRowsThatFit) * ganttConstant.TaskTitleRowHeight;

  height = !isEditing ? textBlockHeight : height - 8;

  return { width, height, newTitle: isEditing ? title : newText };
};
