import type { Reflect } from "@workcanvas/reflect/client";
import type { M } from "shared/datamodel/mutators";
import { customAlphabet } from "nanoid";
import Konva from "konva";
import { GanttSplitCell } from "elements/gantt/layout-builder";
import { ReadTransaction } from "@workcanvas/reflect";
import consts from "shared/consts";
import { canvasElementPrefix, isbitOn } from "shared/util/utils";
import { Flags, GanttConnector, IntegrationItem, TaskCard } from "shared/datamodel/schemas";
import Constants, { GanttAnalyticsEvents, TaskColor, TasksColors } from "elements/gantt/constants";
import tracking from "frontend/tracking";
import { SupportedGanttElements } from "elements/gantt/types";
import { Corners, CornersBitmask } from "utils/shape-utils";

export function createCellId() {
  const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const nanoid = customAlphabet(alphabet, 10);
  return nanoid();
}

export function isTargetSplitCell(target: Konva.Node) {
  return findNodeStartsWithId(target, "cell-");
}

export function isTargetAnchor(target: Konva.Node) {
  return findNodeStartsWithId(target, "anchor-");
}

export function isTargetGridCell(target: Konva.Node) {
  return findNodeStartsWithId(target, "grid-");
}

export function isTargetTaskCell(target: Konva.Node) {
  return findNodeStartsWithId(target, "task-");
}

export function isTargetConnector(target: Konva.Node) {
  return findNodeStartsWithId(target, `${Constants.ConnectorPrefix}-`);
}

function findNodeStartsWithId(node: Konva.Node, id: string) {
  let parent = node;
  while (parent) {
    if (parent.attrs && parent.attrs.id === "gantt-parent-node") {
      return false;
    }
    if (parent.attrs && parent.attrs.id && parent.attrs.id.startsWith(id)) {
      return true;
    }
    parent = parent.getParent();
  }
  return false;
}

const ganttCorners = new Corners(Constants.HeaderBorderRadius);

export function getCornerRadius(cornerRadius: CornersBitmask): number[] {
  const result = ganttCorners.byBitmask(cornerRadius);
  if (!Array.isArray(result) && result < 0) {
    return [0];
  }
  return ganttCorners.byBitmask(cornerRadius);
}

export function getRelatedRows(
  data: (GanttSplitCell | { rowId: string; parentRowId?: string })[],
  rowId: string
): any[] {
  const newData = [...data];
  newData.reverse();

  const relevantIds = new Set();

  newData.forEach((item) => {
    if (item.rowId === rowId || relevantIds.has(item.parentRowId) || relevantIds.has(item.rowId)) {
      relevantIds.add(item.rowId);
      if (item.parentRowId) {
        relevantIds.add(item.parentRowId);
      }
    }
  });

  relevantIds.forEach((id) => {
    newData.forEach((item) => {
      if (item.rowId === id || item.parentRowId === id) {
        relevantIds.add(item.rowId);
      }
    });
  });

  return newData.filter((item) => relevantIds.has(item.rowId));
}

export function getRowColor(color: string): TaskColor {
  if (!color || !TasksColors.includes(color)) {
    return TasksColors[0] as TaskColor;
  }
  return color as TaskColor;
}

export const countGanttTaskNodes = (tasks: SupportedGanttElements[]) =>
  tasks.filter((task) => {
    return (
      task?.containerId?.includes(consts.CANVAS_ELEMENTS.GANTT) &&
      !task.hidden &&
      (!task.hasOwnProperty("flags") || !isbitOn(task?.flags, Flags.CreatedBySystem))
    );
  }).length;

export async function countGanttTaskNodesInReflect(reflect: Reflect<M>): Promise<number> {
  return reflect.query(async function (tx: ReadTransaction) {
    const taskCards = (await tx.scan({ prefix: canvasElementPrefix }).toArray()) as TaskCard[];

    return countGanttTaskNodes(taskCards);
  });
}

type PayloadForEvent<T extends GanttAnalyticsEvents["event"]> = Extract<GanttAnalyticsEvents, { event: T }>["payload"];

export const trackGanttEvent = <E extends GanttAnalyticsEvents["event"]>(
  event: E,
  payload?: PayloadForEvent<E>
): void => {
  tracking.trackAnalyticsEvent(event, payload);
};

export const cleanDate = (d: Date | number) => {
  return new Date(new Date(d).toDateString());
};

export const extractStartAndEndDate = (element: TaskCard | IntegrationItem) => {
  if (element.type === "taskCard") {
    return {
      fromDate: element.fromDate as number,
      toDate: Math.max(element.fromDate ?? -Infinity, element.dueDate ?? -Infinity) as number,
    };
  }
  if (element.type === "integrationItem") {
    if (!element.fieldValues?.["fromDate"] && !element.fieldValues?.["toDate"]) {
      return null;
    }
    return {
      fromDate: element.fieldValues?.["fromDate"] as number,
      toDate: element.fieldValues?.["toDate"] as number,
    };
  }

  return null;
};

const MONDAY_INTEGRATION_POPUP_KEY = "mondayIntegrationPopup-";
export const saveUserSettingForGanttMondayIntegration = (
  integrationId: string | number | undefined | null,
  type: "hide-popup" | "enable-popup"
) => {
  if (!integrationId) {
    return;
  }
  if (type === "hide-popup") {
    sessionStorage.setItem(`${MONDAY_INTEGRATION_POPUP_KEY}${integrationId}`, "true");
  }
  if (type === "enable-popup") {
    sessionStorage.removeItem(`${MONDAY_INTEGRATION_POPUP_KEY}${integrationId}`);
  }
};

export const hasUserSeenMondayIntegrationPopup = (integrationId: string | number | undefined | null) => {
  if (!integrationId) {
    return false;
  }
  return !!sessionStorage.getItem(`${MONDAY_INTEGRATION_POPUP_KEY}${integrationId}`);
};

export const removeDuplicateConnectors = (items: GanttConnector[]): GanttConnector[] => {
  const seen = new Set<string>();
  return items.filter((item) => {
    const key1 = `${item.from}-${item.to}`;
    const key2 = `${item.to}-${item.from}`;
    if (seen.has(key1) || key1 === key2) {
      return false;
    }

    seen.add(key1);
    return true;
  });
};
