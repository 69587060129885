import React from "react";

export const GuestUserError = () => {
  return (
    <div>
      In order to do this operation you need to{" "}
      <a
        style={{
          textDecoration: "underline",
        }}
        target="_blank"
        rel="noopener noreferrer"
        href="/auth/login"
      >
        log in
      </a>
    </div>
  );
};
