import React from "react";
import ReactDOM from "react-dom";
import { RelativePlacement } from "./position-utils";
import style from "./floaters.module.css";
import { Floater, FloaterProps } from "./floater";
import { PortalID } from "frontend/modal/portal-types";

export function Tooltip({
  relativeTo,
  label,
  side = "top",
  boundary,
  customStyle,
  withArrow = true,
}: {
  label: string | JSX.Element;
  relativeTo: FloaterProps["relativeTo"];
  side?: RelativePlacement;
  boundary?: DOMRectReadOnly | string;
  customStyle?: React.CSSProperties;
  withArrow?: boolean;
}) {
  const portal = document.getElementById(PortalID.Tooltips);
  const element = (
    <Floater
      className={style.tooltip}
      relativeTo={relativeTo}
      side={side}
      boundary={boundary}
      extraStyle={customStyle}
      arrowSize={withArrow ? undefined : 0}
    >
      {label}
    </Floater>
  );
  return portal ? ReactDOM.createPortal(element, portal) : element;
}
