import classNames from "classnames";
import { ToolbarProps } from "elements/base/provider";
import { filterSupportedColumns } from "elements/gantt/toolbar/custom-rows/column-utils";
import { DropdownButton } from "frontend/canvas-designer-new/elements-toolbar/toolbar-buttons";
import { useBoardTasks } from "frontend/hooks/use-board-integrations";
import { useCallback } from "react";
import { ButtonGroup } from "react-bootstrap";
import { GanttElement, IntegrationItem } from "shared/datamodel/schemas";
import { useGanttToolbarCustomRows } from "elements/gantt/toolbar/custom-rows/use-toolbar-custom-rows";
import style from "./custom-rows.module.css";
import { groupBy } from "rambda";
import { MondayColumn } from "frontend/hooks/use-board-integrations.types";

type CustomRowsProps = ToolbarProps<GanttElement>;

export const CustomRows = ({ ids, elements, context }: CustomRowsProps) => {
  const { reflect, undoRedoStack, documentId } = context;
  const gantt = elements[0];
  const id = ids[0];
  const { getItem, getBoardIntegration } = useBoardTasks(documentId);

  const extractCustomRowsOptions = useCallback(
    async (data: IntegrationItem[]) => {
      const integrations = Object.entries(groupBy((task: IntegrationItem) => task.integrationId)(data)).map(
        ([integrationId, tasks]) => {
          if (tasks) {
            return [integrationId, tasks.map((task) => task.configuration.itemId).at(0)];
          }
          return [integrationId, null];
        }
      );
      const configurations = (
        await Promise.all(
          integrations.flatMap(async ([integrationId, itemId]) => {
            // const x = getBoardIntegration(integrationId);
            const item = getItem(itemId, integrationId);
            if (item) {
              return item.columns.map((c) => {
                return {
                  ...c,
                  title: `${c.title}`,
                  id: `${integrationId}_${c.id}`,
                };
              });
            }
          })
        )
      )
        .filter((c) => !!c)
        .flat() as MondayColumn[];

      return filterSupportedColumns(configurations);
    },
    [getBoardIntegration, getItem]
  );

  const { title, options, changeCustomRow, currentRowIdentifier } = useGanttToolbarCustomRows({
    gantt,
    id,
    undoRedoStack,
    reflect,
    getOptions: extractCustomRowsOptions,
  });

  function renderOptions() {
    return (
      <div className={style.pickerContainer}>
        {options.map(
          (
            setting: {
              id: string;
              title: string;
              type: string;
            },
            index: number
          ) => {
            return (
              <div
                key={index}
                className={classNames(style.pickerRow, {
                  [style.selected]: setting.id === currentRowIdentifier,
                })}
                onClick={() => {
                  changeCustomRow(setting);
                }}
              >
                <div className={style.pickerCheckmark}>
                  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1 5.5L4.66667 10L12 1"
                      stroke="white"
                      strokeWidth="1.64033"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className={style.pickerRowTitle}>{setting.title}</div>
              </div>
            );
          }
        )}
      </div>
    );
  }

  function renderSelectedOption() {
    return (
      <div className={style.customRowTitle}>
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.757813 0.894531L0.757812 18.8945L18.7578 18.8945L18.7578 0.894531L0.757813 0.894531ZM3.75781 17.6387L2.01363 17.6387L2.01363 2.15034L3.75781 2.15034L3.75781 17.6387ZM5.75781 17.6387L17.502 17.6387L17.502 2.15035L5.75781 2.15034L5.75781 17.6387Z"
            fill="white"
          />
        </svg>

        <span>{title}</span>
      </div>
    );
  }

  if (elements.length > 1 || options.length === 1) {
    return null;
  }

  return (
    <ButtonGroup>
      <div className={style.container}>
        <DropdownButton renderPopup={renderOptions}>{renderSelectedOption()}</DropdownButton>
      </div>
    </ButtonGroup>
  );
};
