import type { Reflect } from "@workcanvas/reflect/client";
import { isEmpty } from "rambda";
import { useMemo } from "react";
import type { M } from "shared/datamodel/mutators";
import { useCanvasMetadata } from "subscriptions";

export const useColumnConfiguration = ({ reflect }: { reflect: Reflect<M> }) => {
  const metadata = useCanvasMetadata(reflect);

  return useMemo(() => {
    const idToIntegrationMetadata = new Map<string, Record<string, string>>();

    for (const [id, metadataItem] of Object.entries(metadata)) {
      const config = metadataItem[0].value;
      const mapping = config.boardColumnsMapping;
      if (!isEmpty(mapping)) {
        idToIntegrationMetadata.set(id, mapping);
      }

      if (config?.integrationIds?.length) {
        for (const id of config.integrationIds) {
          if (!isEmpty(mapping)) {
            idToIntegrationMetadata.set(id, mapping);
          }
        }
      }
    }
    return idToIntegrationMetadata;
  }, [metadata]);
};
