import Konva from "konva";
import { CSSProperties } from "react";

const setMouseCursor = (cursor: CSSProperties["cursor"]) => (e: any, stage?: any) => {
  if (stage) {
    stage.container().style.cursor = cursor;
    return;
  }

  if (e.evt?.target) {
    e.evt.target.style.cursor = cursor;
    return;
  }

  if (e?.currentTarget?.getStage?.()) {
    e.currentTarget.getStage().container().style.cursor = cursor!;
    return;
  }

  if (e?.current && e.current.getStage?.()) {
    e.current.getStage().container().style.cursor = cursor!;
  } else {
    const defaultStage = Konva.stages[0];
    if (defaultStage) {
      defaultStage.container().style.cursor = cursor!;
    }
  }
};

export const setMouseColResize = setMouseCursor("col-resize");
export const setMouseRowResize = setMouseCursor("row-resize");
export const setMouseDrag = setMouseCursor("grab");
export const unsetMouse = setMouseCursor("inherit");
export const resetPointer = setMouseCursor("default");
export const setMousePointer = setMouseCursor("pointer");
export const setMouseText = setMouseCursor("text");
