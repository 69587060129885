import { IRect } from "../utils/math-utils";

class BoundingBox implements DOMRectReadOnly, IRect {
  constructor(
    public minX = Number.MAX_SAFE_INTEGER,
    public minY = Number.MAX_SAFE_INTEGER,
    public maxX = Number.MIN_SAFE_INTEGER,
    public maxY = Number.MIN_SAFE_INTEGER
  ) {}

  public moveBy(dx = 0, dy = 0) {
    return new BoundingBox(this.minX + dx, this.minY + dy, this.maxX + dx, this.maxY + dy);
  }

  static from = (xmin: number, ymin: number, xmax: number, ymax: number) => new BoundingBox(xmin, ymin, xmax, ymax);
  static fromRect = (rect: IRect) => BoundingBox.from(rect.x, rect.y, rect.x + rect.width, rect.y + rect.height);
  static fromCoords = (xys: number[]) => {
    let minX = Number.MAX_SAFE_INTEGER;
    let minY = Number.MAX_SAFE_INTEGER;
    let maxX = Number.MIN_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;
    for (let i = 0; i < xys.length; i += 2) {
      minX = Math.min(minX, xys[i]);
      minY = Math.min(minY, xys[i + 1]);
      maxX = Math.max(maxX, xys[i]);
      maxY = Math.max(maxY, xys[i + 1]);
    }
    return new BoundingBox(minX, minY, maxX, maxY);
  };

  static fromPoints = (points: { x: number; y: number }[]) => {
    let minX = Number.MAX_SAFE_INTEGER;
    let minY = Number.MAX_SAFE_INTEGER;
    let maxX = Number.MIN_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;
    for (const point of points) {
      minX = Math.min(minX, point.x);
      minY = Math.min(minY, point.y);
      maxX = Math.max(maxX, point.x);
      maxY = Math.max(maxY, point.y);
    }
    return new BoundingBox(minX, minY, maxX, maxY);
  };

  static empty = () => new BoundingBox();

  static concat = (a: BoundingBox, b: BoundingBox) =>
    new BoundingBox(
      Math.min(a.minX, b.minX),
      Math.min(a.minY, b.minY),
      Math.max(a.maxX, b.maxX),
      Math.max(a.maxY, b.maxY)
    );

  static isValid = (b: BoundingBox) => b.minX <= b.maxX && b.minY <= b.maxY;

  public contains = (other: IRect) => {
    return (
      other.x >= this.minX &&
      other.y >= this.minY &&
      other.x + other.width <= this.maxX &&
      other.y + other.height <= this.maxY
    );
  };

  public addPadding = (padding: number) =>
    new BoundingBox(this.minX - padding, this.minY - padding, this.maxX + padding, this.maxY + padding);

  public static expandOnAll(rects: IRect[]) {
    const total = new BoundingBox();
    for (const rect of rects) {
      total.expandRect(rect);
    }
    return total;
  }

  toJSON() {
    return `{x:${this.left},y:${this.top},width:${this.width},height:${this.height}}`;
  }

  public expandRect(rect: IRect) {
    this.minX = Math.min(this.minX, rect.x);
    this.minY = Math.min(this.minY, rect.y);
    this.maxX = Math.max(this.maxX, rect.x + rect.width);
    this.maxY = Math.max(this.maxY, rect.y + rect.height);
    return this;
  }

  public expandPoint(x: number, y: number) {
    this.minX = Math.min(this.minX, x);
    this.minY = Math.min(this.minY, y);
    this.maxX = Math.max(this.maxX, x);
    this.maxY = Math.max(this.maxY, y);
    return this;
  }

  get left() {
    return this.minX;
  }
  get right() {
    return this.maxX;
  }
  get top() {
    return this.minY;
  }
  get bottom() {
    return this.maxY;
  }
  get x() {
    return this.minX;
  }
  get y() {
    return this.minY;
  }
  get width() {
    return this.maxX - this.minX;
  }
  get height() {
    return this.maxY - this.minY;
  }
  get size() {
    return { width: this.width, height: this.height };
  }
  get center() {
    return { x: this.minX + this.width / 2, y: this.minY + this.height / 2 };
  }
  asRect() {
    return { x: this.x, y: this.y, width: this.width, height: this.height };
  }

  static moveBBoxOutside(bbox1: IRect, bbox2: IRect): IRect {
    const bbox1Right = bbox1.x + bbox1.width;
    const bbox1Bottom = bbox1.y + bbox1.height;
    const bbox2Right = bbox2.x + bbox2.width;
    const bbox2Bottom = bbox2.y + bbox2.height;

    let moveX = 0;
    let moveY = 0;

    // Check if bbox1 is overlapping bbox2
    if (bbox1.x < bbox2Right && bbox1Right > bbox2.x && bbox1.y < bbox2Bottom && bbox1Bottom > bbox2.y) {
      // Calculate smallest movement to push bbox1 out of bbox2
      const moveLeft = bbox2.x - bbox1Right;
      const moveRight = bbox2Right - bbox1.x;
      const moveUp = bbox2.y - bbox1Bottom;
      const moveDown = bbox2Bottom - bbox1.y;

      // Choose the smallest absolute movement
      const minX = Math.abs(moveLeft) < Math.abs(moveRight) ? moveLeft : moveRight;
      const minY = Math.abs(moveUp) < Math.abs(moveDown) ? moveUp : moveDown;

      if (Math.abs(minX) < Math.abs(minY)) {
        moveX = minX;
      } else {
        moveY = minY;
      }
    }

    return {
      x: bbox1.x + moveX,
      y: bbox1.y + moveY,
      width: bbox1.width,
      height: bbox1.height,
    };
  }
}

export default BoundingBox;
