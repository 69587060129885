import { differenceInMilliseconds } from "date-fns";
import type { IGanttController } from "elements/gantt/controller";
import { IGanttBaseCellController } from "elements/gantt/controllers/base-cell-controller";
import { combineMappingWithData } from "elements/gantt/controllers/controller-utils";
import { useBoardTasks } from "frontend/hooks/use-board-integrations";
import { equals, groupBy, isEmpty } from "rambda";
import { useEffect, useState } from "react";
import { IntegrationItem } from "shared/datamodel/schemas";
import { canvasMetadataPrefix } from "shared/util/utils";
import { useRawCanvasMetadata } from "subscriptions";

export const useGanttSyncMondayItems = ({ controller }: { controller: IGanttController }) => {
  const metadata = useRawCanvasMetadata(controller.context.reflect);
  const [mondayTasks, setMondayTasks] = useState<{ [index: string]: IGanttBaseCellController<IntegrationItem>[] }>({});

  const pusherChannel = controller.context.pusherChannel;

  const { dataSource, loadingBoardIntegrationsState } = useBoardTasks(controller.context.documentId, pusherChannel);

  useEffect(() => {
    const setTasks = () => {
      const tasks = controller
        .getTaskCells()
        .filter((t) => t.element.type === "integrationItem") as IGanttBaseCellController<IntegrationItem>[];

      const tasksByIntegratinId = groupBy(
        (task: IGanttBaseCellController<IntegrationItem>) => task.element.integrationId
      )(tasks);
      if (!equals(tasksByIntegratinId, mondayTasks) && !isEmpty(tasksByIntegratinId)) {
        setMondayTasks(tasksByIntegratinId);
      }
    };
    controller.subscribe(setTasks);

    return () => controller.unsubscribe(setTasks);
  }, [controller, mondayTasks]);

  useEffect(() => {
    if (!isEmpty(dataSource) && !isEmpty(mondayTasks)) {
      for (const [integrationId, { items, boardId }] of Object.entries(dataSource)) {
        const integrationMetadata = metadata.find(([id]) => id === `${canvasMetadataPrefix}${boardId}`);
        const tasks = mondayTasks[integrationId] ?? [];
        for (const task of tasks) {
          const item = items.find((item) => item.id === task.element.configuration?.itemId);
          if (item && !integrationMetadata) {
            // only patch title
            controller.context.undoRedoStack.patchElement(task.id, (draft: IntegrationItem) => {
              draft.fieldValues ??= {};
              draft.fieldValues["title"] = item?.name;
            });
            continue;
          }
          if (!item || !integrationMetadata) {
            continue;
          }
          const taskConfig = integrationMetadata[1].boardColumnsMapping;
          if (taskConfig) {
            const newTaskData = combineMappingWithData(
              { mapping: taskConfig as Record<string, string>, shouldApplyForAll: false },
              item
            );

            if (task.isInitialLoading?.()) {
              controller.context.undoRedoStack.patchElement(task.id, (draft: IntegrationItem) => {
                const hasJustBeenPlaces = differenceInMilliseconds(draft.fieldValues?.placedAt, Date.now()) < 100;
                draft.fieldValues ??= {};
                if (
                  newTaskData.startDate &&
                  newTaskData.endDate &&
                  newTaskData.startDate <= newTaskData.endDate &&
                  !hasJustBeenPlaces
                ) {
                  draft.fieldValues["fromDate"] = newTaskData.startDate;
                  draft.fieldValues["toDate"] = newTaskData.endDate;
                }
                delete draft.fieldValues["placedAt"];
                draft.fieldValues["title"] = newTaskData.title;
              });
            } else {
              controller.context.undoRedoStack.patchElement(task.id, (draft: IntegrationItem) => {
                draft.fieldValues ??= {};
                draft.fieldValues["title"] = newTaskData.title;
              });
            }

            task.updateIntegrationConfig?.({
              dependencies: newTaskData.dependencies,
              url: item.url || "",
              dependencyId: taskConfig.dependency,
              endId: taskConfig.end_date,
              startId: taskConfig.start_date,
            });
          }
        }
      }
      controller.notify();
    }
  }, [
    controller.getTaskCells().length,
    JSON.stringify(dataSource),
    mondayTasks,
    loadingBoardIntegrationsState,
    metadata,
  ]);
};
