import { ZodSchema, ZodTypeDef } from "zod";
import type { TypeCanvasElement } from "shared/consts";
import type { CanvasElement } from "shared/datamodel/schemas";
import type { ElementController } from "./controller";
import type { BoardContext } from "elements/index";
import React from "react";
import { getFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";

export interface ElementProps<T extends CanvasElement> {
  id: string;
  controller: ElementController<T>;
}

type ToolbarContext = Pick<BoardContext, "reflect" | "undoRedoStack" | "showUpgradeModal" | "documentId">;

export interface ToolbarProps<T extends CanvasElement> {
  ids: string[];
  elements: T[];
  context: ToolbarContext;
}

export interface ElementProvider<T extends CanvasElement> {
  /**
   * Create a controller for the element
   * @param id the id of the element
   * @param element the element
   * @param context the board context
   * @param allElementsData all the other elements on the canvas
   */
  createController(
    id: string,
    element: T,
    context: BoardContext,
    allElementsData?: [string, any][]
  ): ElementController<T>;

  /**
   * Create new element and change its id and inner properties if needed
   * @param props the element properties
   */
  createElement?(props: any): T;

  /**
   * Get the element renderer
   */
  getElementRenderer(): React.FC<ElementProps<T>>;

  /**
   * Get the toolbar renderer
   */
  getToolbarRenderer?(): React.FC<ToolbarProps<T>>;

  /**
   * Get the element schema
   */
  getElementSchema(): ZodSchema;

  /**
   * Get the element type
   */
  getType(): TypeCanvasElement;

  /**
   * Returns if the elements supports being connected to other elements
   */
  isConnectorsEnabled(): boolean;

  /**
   * Determines if the link badge feature is enabled for the element.
   * The link badge is a UI element that can indicate a hyperlink or an association.
   * @returns {boolean} True if the link badge feature is enabled, false otherwise.
   */
  isLinkBadgeEnabled(): boolean;

  /**
   * Determines if the element can be rotated.
   * This can be useful for elements that need to be oriented in different directions.
   * @returns {boolean} True if rotation is enabled for the element, false otherwise.
   */
  isRotatableEnabled(): boolean;

  /**
   * Determines if text editing is enabled for the element.
   * This allows the element to contain and display text content that can be edited.
   * @returns {boolean} True if text editing is enabled, false otherwise.
   */
  isTextEnabled(): boolean;

  /**
   * Determines if voting is enabled for the element.
   * This feature can be used in collaborative environments to gather votes or preferences on elements.
   * @returns {boolean} True if voting is enabled for the element, false otherwise.
   */
  isVotingEnabled(): boolean;

  /**
   * Validate element schema
   * @param element the element being validated
   */
  validateElement(element: any): T;

  isFFEnabled(): boolean;
}

export class BaseElementProvider<T extends CanvasElement> implements ElementProvider<T> {
  /* eslint-disable @typescript-eslint/no-unused-vars */

  getElementRenderer(): React.FC<ElementProps<T>> {
    throw new Error("Method not implemented.");
  }

  getType(): TypeCanvasElement {
    throw new Error("Method not implemented.");
  }

  getElementSchema(): ZodSchema<any, ZodTypeDef, any> {
    throw new Error("Method not implemented.");
  }

  validateElement<T>(element: any): T {
    return this.getElementSchema().parse(element);
  }

  isConnectorsEnabled(): boolean {
    return true;
  }

  createController(
    id: string,
    element: T,
    context: BoardContext,
    allElementsData?: [string, any][]
  ): ElementController<T> {
    throw new Error("Method not implemented.");
  }

  isLinkBadgeEnabled(): boolean {
    return false;
  }

  isVotingEnabled(): boolean {
    return false;
  }

  isRotatableEnabled(): boolean {
    return false;
  }

  isTextEnabled(): boolean {
    return false;
  }

  isFFEnabled(): boolean {
    return getFeatureFlag(`canvas-element-${this.getType()}`);
  }
}
