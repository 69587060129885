import { GanttSplitCell } from "elements/gantt/layout-builder";
import type { IGanttController } from "elements/gantt/controller";
import { IObservableController, ObservableController } from "elements/base/observable";
import { getRowColor } from "elements/gantt/utils";
import { TaskColor } from "../constants";
import { CornersBitmask } from "utils/shape-utils";

export interface IGanttSplitCellController extends IObservableController {
  updateCellLayout(cellLayout: GanttSplitCell): void;

  getRowId(): string;

  getSplitId(): string;

  getTitle(): string;

  getColor(): TaskColor;

  changeColor(color: string): void;

  getCornerRadius(): CornersBitmask;

  splitType(): "primary" | "secondary" | "regular";

  getRect(): { x: number; y: number; width: number; height: number; cornerRadius: CornersBitmask };

  selectRow(): void;

  isSelected(): boolean;

  setSelected(selected: boolean): void;

  setEditing(editing: boolean): void;

  isEditing(): boolean;

  changeTitle(title: string): void;

  deleteRow(): void;

  addRow(): void;

  addColumn(): void;

  canDelete(): boolean;

  isCustomRowSplitMode(): boolean;
}

export class GanttSplitCellController extends ObservableController implements IGanttSplitCellController {
  #isSelected: boolean = false;
  #isEditing: boolean = false;

  constructor(private cellLayout: GanttSplitCell, private ganttController: WeakRef<IGanttController>) {
    super();
  }

  updateCellLayout(cellLayout: GanttSplitCell) {
    this.cellLayout = cellLayout;
    this.notify();
  }

  getRowId() {
    return this.cellLayout.rowId;
  }

  getSplitId() {
    return this.cellLayout.splitId;
  }

  getTitle() {
    return this.cellLayout.title;
  }

  getColor() {
    if (this.isCustomRowSplitMode()) {
      return this.cellLayout.color as TaskColor;
    }
    return getRowColor(this.cellLayout.color);
  }

  changeColor(color: string): void {
    const { splitId, rowId } = this.cellLayout;
    this.ganttController.deref()?.changeRowColor(splitId, rowId, color);
  }

  getCornerRadius() {
    return this.cellLayout.cornerRadius;
  }

  splitType(): "primary" | "secondary" | "regular" {
    switch (this.cellLayout.splitIndex) {
      case 0: {
        return "primary";
      }
      case 1: {
        return "secondary";
      }
      default: {
        return "regular";
      }
    }
  }

  getRect(): { x: number; y: number; width: number; height: number; cornerRadius: CornersBitmask } {
    const layout =
      this.ganttController.deref()?.getCellLayout(this.cellLayout.splitId, this.cellLayout.rowId) ?? this.cellLayout;
    const { x, y, width, height, cornerRadius } = layout;
    return { x, y, width, height, cornerRadius };
  }

  selectRow() {
    this.ganttController.deref()?.setSelectedColumnRow(this.getSplitId(), this.getRowId());
  }

  setSelected(selected: boolean) {
    if (this.#isSelected === selected) {
      return;
    }
    this.#isSelected = selected;
    if (!selected) {
      this.#isEditing = false;
    }
    this.notify();
  }

  isSelected() {
    return this.#isSelected;
  }

  setEditing(editing: boolean) {
    if (this.#isEditing === editing) {
      return;
    }
    this.#isEditing = editing;
    this.notify();
  }

  isEditing(): boolean {
    return this.#isEditing && this.isSelected();
  }

  changeTitle(title: string) {
    const { splitId, rowId } = this.cellLayout;
    this.ganttController.deref()?.changeRowTitle(splitId, rowId, title);
  }

  deleteRow() {
    const { splitId, rowId } = this.cellLayout;
    this.ganttController.deref()?.deleteRow(splitId, rowId);
  }

  addRow() {
    const { splitId, rowId } = this.cellLayout;
    this.ganttController.deref()?.addSplitRowBelow(splitId, rowId);
  }

  addColumn() {
    const { splitId } = this.cellLayout;
    this.ganttController.deref()?.addSplitColumnRight(splitId);
  }

  canDelete(): boolean {
    const { splitId, rowId } = this.cellLayout;
    return this.ganttController.deref()?.canDeleteRow(splitId, rowId) ?? false;
  }

  isCustomRowSplitMode() {
    return this.ganttController.deref()?.element.customRows !== undefined;
  }
}
