import { useAtomValue, useSetAtom } from "jotai";
import React, { useEffect } from "react";
import {
  isAnyKindOfExportAtom,
  mondayOrgChartNodeDataForSidePanel,
  sidePanelType,
  SidepanelType,
  transformerRefAtom,
} from "state-atoms";
import { Group, Rect, Text } from "react-konva";
import consts from "shared/consts";
import { ITraits, Trait } from "../../elements-toolbar/elements-toolbar-types";
import { useCurrentCanvasValue } from "../../canvas-context";
import { MindmapNodeOrientation, MindmapOrgChartNodeElement } from "shared/datamodel/schemas/mindmap-org-chart";
import {
  DEFAULT_COLOR,
  NODE_DEFAULT_FIELDS_COUNT,
  NODE_ADDITIONAL_HEIGHT,
  ORGCHART_NODE_DEFAULT_WIDTH,
  TEXT_FIELD_HEIGHT,
  colorDecider,
} from "shared/datamodel/mindmap-org-chart";
import { useMondayOrgChart } from "frontend/hooks/use-board-integrations";
import { OrgChart1 } from "./standalone/orgchart-element";
import * as treeUtils from "./standalone/tree-layout-utils";
import {
  OrgchartCollapseButtonHorizontal as CollapseButtonH,
  OrgchartCollapseButtonVertical as CollapseButtonV,
} from "./standalone/orgchart-collapse-subtree-button";
import {
  DEPARTMENT_ID,
  DRAW_PROPS_ID,
  NAME_ID,
  OrgChartOrientation,
  PHOTO_ID,
  ROLE_ID,
} from "shared/datamodel/schemas/org-chart";
import { OrgChartViewOnlyNode } from "./standalone/orgchart-node";
import * as utils from "./orgchart-utils";
import { ColorNames } from "frontend/utils/color-utils";
import { MindmapOrgChartGhostElement } from "frontend/canvas-designer-new/elements/org-chart/mindmap-org-chart-ghost-element";
import { LiveIntegrationColumn } from "frontend/canvas-designer-new/elements-toolbar/widgets/live-integration/utils";
import { useFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";

type ChangeElementCb = (
  props: any,
  undoConfig: { shouldAdd: boolean; previousProps?: any },
  updateSubMenuData?: any,
  elementId?: string
) => void;

export const MONDAY_ORG_CHART_DEFAULT_COLOR = 5;

//----------------------------------------------

const MemoizedMindmapOrgChartElement = React.memo(function ({
  id,
  element,
  changeElement,
  shouldExcludeRestrictedColumns,
  shouldReduceLoadingTime,
}: {
  id: string;
  element: MindmapOrgChartNodeElement;
  changeElement: ChangeElementCb;
  shouldExcludeRestrictedColumns: boolean;
  shouldReduceLoadingTime: boolean;
}) {
  const { configuration } = element;
  const [{ documentId, pusherChannel }] = useCurrentCanvasValue();
  const { items, isLoaded, pusherTimestamp } = useMondayOrgChart(
    id,
    documentId,
    element.integrationId,
    pusherChannel,
    shouldExcludeRestrictedColumns,
    Object.values(configuration?.columnMapping || {}),
    shouldReduceLoadingTime
  );
  const transformerRef = useAtomValue(transformerRefAtom);

  const shouldEnableDepartmentAsRoot = useFeatureFlag("orgchart-department-enabled");

  useEffect(() => {
    if (pusherTimestamp !== "") {
      changeElement({ pusherTimestamp: pusherTimestamp }, { shouldAdd: false }, undefined, id);
      setTimeout(() => transformerRef && transformerRef.current && transformerRef.current.forceUpdate());
    }
  }, [pusherTimestamp]);

  if (!isLoaded || items == null) {
    const x = element.x;
    const y = element.y;
    return (
      <Group
        id={id}
        name={id}
        element={element}
        isCanvasElement={true}
        isSelectable={true}
        isConnectable={false}
        isConnector={false}
        isDraggable={true}
        isFrame={false}
      >
        <MindmapOrgChartGhostElement x={x - 70} y={y} />
      </Group>
    );
  }

  if (isLoaded && utils.isMissingDataForChart(items, element.rootId, shouldEnableDepartmentAsRoot)) {
    const rectLength = ORGCHART_NODE_DEFAULT_WIDTH;
    const x = element.x;
    const y = element.y;
    return (
      <Group
        x={x - rectLength / 2}
        y={y - rectLength / 2}
        id={id}
        name={id}
        type={consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART}
        element={element}
        isCanvasElement={true}
        isSelectable={true}
        isConnectable={false}
        isConnector={false}
        isDraggable={true}
        isFrame={false}
      >
        <Rect
          width={rectLength}
          height={rectLength}
          shadowColor={"rgba(0, 0, 0, 0.12)"}
          shadowBlur={12}
          shadowOffsetY={4}
          stroke={"#EBEDF3"}
          strokeWidth={1}
          shadowForStrokeEnabled={false}
          fill={"white"}
        />
        <Text
          fontSize={24}
          width={rectLength}
          height={rectLength}
          padding={10}
          align="center"
          verticalAlign="middle"
          text="Cannot find the needed data in the source board"
          wrap="word"
        />
      </Group>
    );
  }

  return (
    <OrgChartTest1
      uniqueId={id}
      element={element}
      items={items}
      changeElement={changeElement}
      shouldEnableDepartmentAsRoot={shouldEnableDepartmentAsRoot}
    />
  );
});

MemoizedMindmapOrgChartElement.displayName = "MemoizedMindmapOrgChartElement";
export default MemoizedMindmapOrgChartElement;

function buildLayoutAndData(
  items: any,
  expandedNodes: string[],
  selectedColorFieldId = "",
  rootId: string,
  columns: LiveIntegrationColumn[],
  shouldEnableDepartmentAsRoot: boolean
) {
  const layout: Record<string, utils.NodeLayout> = {},
    namesToItems: any = {},
    data: Record<string, any> = {};

  const isDepartmentAsSource = shouldEnableDepartmentAsRoot && utils.isDepartmentNode(rootId);

  function initDepartmentLayoutSource(departmentId: string): utils.NodeLayout {
    return { id: departmentId, collapsed: true, childrenIds: [] };
  }

  function initDepartmentData(columnId: string, departmentId: string) {
    const labels = utils.extractLabelsFromDepartment(columns, columnId);

    return {
      [DEPARTMENT_ID]: labels[departmentId],
      [DRAW_PROPS_ID]: {
        lineWidth: 2.5,
        dash: 0,
        color: DEFAULT_COLOR,
      },
    };
  }

  function addDepartmentRootNode() {
    const { columnId, departmentId } = utils.parseDepartmentFromRootId(rootId);
    layout[departmentId] = initDepartmentLayoutSource(departmentId);
    data[departmentId] = initDepartmentData(columnId, departmentId);
  }

  function isItemDirectDescendantOfDepartment(item: any, sourceDepartment: string) {
    const { columnValues } = item;
    const itemDepartment = columnValues.find((c: any) => c.id === columnId)?.value?.text;
    return sourceDepartment === itemDepartment;
  }

  if (isDepartmentAsSource) addDepartmentRootNode();

  // build layout map and data map of nodes
  for (const item of items) {
    const { id, self } = item;
    layout[id] = { id, childrenIds: [], collapsed: true };
    const color = colorDecider(selectedColorFieldId, item);

    data[id] = {
      [NAME_ID]: item.self,
      [ROLE_ID]: item.job_title,
      [PHOTO_ID]: "",
      [DRAW_PROPS_ID]: {
        lineWidth: 1,
        dash: 0,
        color: color,
      },
      email: item.email,
    };
    item.columnValues.forEach((col: any) => {
      let value = col.value;
      if (col.type == "status") value = value.text;
      data[id][col.id] = value;
    });
    namesToItems[self] = item;
  }

  // toggle the expanded nodes
  for (const expanded of expandedNodes) {
    if (expanded in layout) layout[expanded].collapsed = false;
  }

  const { columnId, departmentId } = utils.parseDepartmentFromRootId(rootId);

  let sourceDepartment: string;
  if (isDepartmentAsSource) {
    const departmentLabels = utils.extractLabelsFromDepartment(columns, columnId);
    sourceDepartment = departmentLabels[departmentId] as string;
  }

  // build parent-children relationship
  for (const item of items) {
    const { parent, id } = item;
    if (!parent || !namesToItems[parent]) {
      if (!isDepartmentAsSource) continue;

      if (isItemDirectDescendantOfDepartment(item, sourceDepartment!)) {
        item.parent = departmentId;
        layout[departmentId].childrenIds.push(id);
      }
    } else {
      layout[namesToItems[parent].id].childrenIds.push(id);
    }
  }

  return { layout, data };
}

function OrgChartTest1({
  uniqueId,
  element,
  items,
  changeElement,
  shouldEnableDepartmentAsRoot,
}: {
  uniqueId: string;
  element: MindmapOrgChartNodeElement;
  items: any[];
  changeElement: ChangeElementCb;
  shouldEnableDepartmentAsRoot: boolean;
}) {
  const setOpenedSidePanel = useSetAtom(sidePanelType);
  const setSidePanelData = useSetAtom(mondayOrgChartNodeDataForSidePanel);
  const isExporting = useAtomValue(isAnyKindOfExportAtom);
  const shouldEnableFilterByDropdownColumns = useFeatureFlag("orgchart-filters-column-dropdown");

  function openEditor(nodeId: string) {
    setOpenedSidePanel(SidepanelType.mondayOrgChartNodeInfo);
    setSidePanelData(items.find((item) => item.id === nodeId));
  }

  // orientation in mindmap-orgchart is reversed !!! fix that !!!
  const orientation =
    element.orientation == MindmapNodeOrientation.HORIZONTAL
      ? OrgChartOrientation.TopToBottom
      : OrgChartOrientation.LeftToRight;

  const NodeWidth = 150;
  const SeparationLayers = 100;

  const { expandedNodes = [], selectedFields = [], rootId, selectedColorFieldId, selectedFilter } = element;
  const height = (selectedFields.length + NODE_DEFAULT_FIELDS_COUNT) * TEXT_FIELD_HEIGHT + NODE_ADDITIONAL_HEIGHT;

  const layoutUtil = treeUtils.TreeLayoutHelper.from(orientation, NodeWidth, height, SeparationLayers);
  const Btn = orientation == "TB" ? CollapseButtonH : CollapseButtonV;

  // items = selectedFilter && Object.keys(selectedFilter).length ? filterItems(selectedFilter, items) : items;
  const { layout, data } = buildLayoutAndData(
    items,
    expandedNodes,
    selectedColorFieldId,
    rootId,
    element.configuration.columns,
    shouldEnableDepartmentAsRoot
  );

  if (selectedFilter && Object.keys(selectedFilter).length) {
    const rejected = rejectItems(selectedFilter, items, shouldEnableFilterByDropdownColumns);
    for (const item of rejected) data[item.id][DRAW_PROPS_ID].color = "#aaaaaa";
  }

  const collapseButtonOffset = layoutUtil.getNodeSocket("toLeaves", 12);

  function renderCollapseButton(
    nodeId: string,
    collapsed: boolean,
    x: number,
    y: number,
    direct: number,
    total: number
  ) {
    const color = data[nodeId][DRAW_PROPS_ID].color;
    let btncolor = collapsed ? color : utils.lightenColor(color);

    if (btncolor === ColorNames.white) {
      btncolor = utils.lightenColor(color, 0.15);
    }

    return (
      <Btn
        key={"collapse" + nodeId}
        x={x + collapseButtonOffset.x}
        y={y + collapseButtonOffset.y}
        belongsTo={nodeId}
        color={btncolor}
        collapsed={collapsed}
        direct={direct}
        indirect={total}
        onClick={function () {
          let postExpandedNodes;
          if (collapsed) {
            postExpandedNodes = [...expandedNodes, nodeId];
          } else {
            postExpandedNodes = expandedNodes.filter((x) => x !== nodeId);
          }
          changeElement(
            { expandedNodes: postExpandedNodes },
            { shouldAdd: true, previousProps: { expandedNodes } },
            undefined,
            uniqueId
          );
          //TODO: refresh the transformer
        }}
      />
    );
  }

  const fieldsMetadata = element.configuration.columns.reduce((acc: any, cur: any) => {
    acc[cur.id] = {
      id: cur.id,
      type: cur.type,
      name: cur.title,
      hidden: false,
    };
    return acc;
  }, {});

  return (
    <OrgChart1
      x={element.x}
      y={element.y}
      rootNodeId={rootId}
      layout={layout}
      data={data}
      orientation={orientation}
      nodeHeight={height}
      getEdgeDrawProps={(nodeId: string) => ({ strokeWidth: 1, dash: 0, stroke: data[nodeId][DRAW_PROPS_ID].color })}
      selectedFieldsLength={selectedFields?.length}
      renderNode={function (
        nodeId: string,
        x: number,
        y: number,
        height: number,
        data: any,
        isRoot: boolean,
        rootType = utils.SourceNodeType.Person
      ): React.ReactNode {
        const isDepartmentAsSource = utils.isDepartmentAsSource(rootType, shouldEnableDepartmentAsRoot);

        return (
          <OrgChartViewOnlyNode
            key={nodeId}
            id={uniqueId}
            position={{ x, y }}
            width={150}
            height={height}
            data={data}
            showImage={false}
            fieldsToShow={selectedFields}
            fieldsTitle={fieldsMetadata}
            nodeDrawProps={data[DRAW_PROPS_ID]}
            type={consts.CANVAS_ELEMENTS.ORG_CHART}
            isRoot={isRoot}
            isSelected={false} // monday-orgchart needs different ids per element
            element={element}
            isSidePanelOpenForMe={false}
            onOpenPanel={() => openEditor(nodeId)}
            onClick={undefined}
            setTooltip={() => {
              console.log("not implemented");
            }}
            isMindmapOrgChart={true}
            isExporting={isExporting}
            isTextOnly={isDepartmentAsSource}
            text={isDepartmentAsSource ? data[DEPARTMENT_ID] : undefined}
          />
        );
      }}
      renderCollapseButton={renderCollapseButton}
      renderAddNodeButtons={undefined}
      shouldEnableDepartmentAsRoot={shouldEnableDepartmentAsRoot}
    />
  );
}

interface ISelectedFilter {
  [columnId: string]: string[];
}

function rejectItems(selectedFilter: ISelectedFilter, items: any, shouldEnableFilterByDropdownColumns: boolean) {
  return items.filter((item: any) => {
    for (const [columnId, values] of Object.entries(selectedFilter)) {
      const column = item.columnValues.find((col: any) => col.id === columnId);

      if (values.length === 0) {
        return false;
      }

      if (column?.type === "status") {
        if (!values.includes(column.value.text)) {
          return true;
        }
      } else if (shouldEnableFilterByDropdownColumns && column?.type === "dropdown") {
        const itemDropdownValues = column.value.split(",").map((value: string) => value.trim());
        if (!values.some((value: string) => itemDropdownValues.includes(value))) {
          return true;
        }
      } else {
        if (!column || !values.includes(column.value)) {
          return true;
        }
      }
    }

    return false;
  });
}

export function mindmapOrgChartTraits(element: MindmapOrgChartNodeElement): ITraits {
  if (element.parentId || !element.configuration.hasOwnProperty("columns")) {
    return {};
  }
  const colorByOptions = element.configuration.columns
    .filter((col: { type: string }) => col.type === "status")
    .map((col: { id: string }) => ({
      ...col,
      selected: element.selectedColorFieldId === col.id,
    }));
  colorByOptions.unshift({
    id: "#none",
    title: "Nothing",
    selected: element.selectedColorFieldId === "#none",
    value: { color: "#7549F5" },
  });
  // root node
  return {
    mindmapOrgChartColor: colorByOptions,
    mindmapOrientation: element.orientation,
    mindmapOrgChartFields: element.configuration.columns.map((col: any) => ({
      ...col,

      selected: element.selectedFields?.includes(col.id),
    })),
    mindmapOrgChartColumnValues: {
      items: element.childrenColumnValues,
      selected: element.selectedFilter,
      titles: element.configuration.columns.reduce(
        (acc: { [column: string]: string }, item: { id: string; title: string }) => {
          acc[item.id] = item.title;
          return acc;
        },
        {}
      ),
      integrationId: element.integrationId,
    },
  };
}

export function mindmapOrgChartValidateTraits(element: MindmapOrgChartNodeElement, trait: Trait, value: any) {
  if (element.parentId || !element.configuration.hasOwnProperty("columns")) {
    return {};
  }
  if (trait == Trait.mindmapOrgChartColumnValues) {
    return { selectedFilter: value };
  } else {
    return value;
  }
}
